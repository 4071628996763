import { LightboxContentScript } from '@greenova/smartoverlay/dist/overlayContentScript';
import { IOverlayClient } from './IOverlayClient.public';

// LightboxContentScript extended for Smasi1 backward compatibility
class OverlayClient extends LightboxContentScript implements IOverlayClient {

    constructor() {
        super();
    }

    public closeByUser(): void {
        this.close();
    }

    public panelAction(actionToken: string, closePanel: boolean, userForm: any): void {
        this.trigger({
            action: actionToken, closeAfter: closePanel, userForm: userForm
        });
    }

    //// Can be called by content as a notify when the content is
    //// completely loaded and ready to displayed. 
    //// The content must enable this behaviour by setting
    //// window.lightboxConfig.asyncContent = true
    //// before this script is loaded.
    //// When this feature is enabled, the lightbox won't open until
    //// this notify is called.
    //public asyncContentReady(): void {
    //    this._chan.notify({
    //        method: "asyncContentReady"
    //    });
    //}
    // Can be called by content as a notify when a button is clicked. 
    // This is for external templates where the ClickedAt timestamp can't be written to [dbo].[TrackingActionVoucher] automatically.
    public clicked(): void {
        this.trigger({
            method: "clicked"
        });
    }

}

const client = new OverlayClient();

(<any>window).smartOverlay = client;

// used for smasi1 compatibility
(<any>window).smartLightbox = client;


